import { Box, Stack, Typography, useMediaQuery } from '@mui/material';
import classNames from 'classnames';
import { array, node, object, string } from 'prop-types';
import React from 'react';

import ConfidenceIcon from '../../../assets/icons/confidence.svg';
import { PaginationLinks } from '../../../components';
import { propTypes } from '../../../util/types';

import RecentItemCard from '../../LandingPage/SectionRecentItems/RecentItemCard/RecentItemCard';
import css from './SearchResultsPanel.module.css';

function SearchResultsPanel(props) {
  const {
    className,
    rootClassName,
    listings,
    pagination,
    search,
    pathParams,
    intl,
    likedListingsIds,
    addUserFavorite,
    removeUserFavorite,
    currentUserId,
    resultsCount,
  } = props;

  const classes = classNames(rootClassName || css.root, className);
  const isMobile = useMediaQuery(theme => theme.breakpoints.between('xs', 'md'));
  const isTablet = useMediaQuery(theme => theme.breakpoints.between('md', 'lg'));
  const isDesktop = useMediaQuery(theme => theme.breakpoints.up('lg'));

  const paginationLinks =
    pagination && pagination.totalPages > 1 ? (
      <PaginationLinks
        className={css.pagination}
        pageName="SearchPage"
        pageSearchParams={search}
        pagination={pagination}
        pagePathParams={pathParams}
      />
    ) : null;

  return (
    <div className={classes}>
      {/* <div className={css.resultsHeader}>
        {intl.formatMessage({ id: 'search_page.header.foundResults' }, { count: resultsCount })}
      </div> */}
      <div className={css.listingCards}>
        {listings.map((l, index) => {
          const mobileIndexFromEnd = Math.min(1, listings.length);
          const tabletIndexFromEnd = Math.min(2, listings.length);
          const desktopIndexFromEnd = Math.min(3, listings.length);

          const shouldRenderUpsell =
            (isMobile && index === listings.length - mobileIndexFromEnd) ||
            (isTablet && index === listings.length - tabletIndexFromEnd) ||
            (isDesktop && index === listings.length - desktopIndexFromEnd);

          if (shouldRenderUpsell) {
            return (
              <React.Fragment key={`upsell-${index}`}>
                <RecentItemCard
                  listing={l}
                  key={l.id.uuid}
                  rootClassName={css.listingCard}
                  rootImageClassName={css.listingImage}
                  intl={intl}
                  likedListingsIds={likedListingsIds}
                  addUserFavorite={addUserFavorite}
                  removeUserFavorite={removeUserFavorite}
                  currentUserId={currentUserId}
                />
                {renderUpsellCard()}
              </React.Fragment>
            );
          }

          return (
            <RecentItemCard
              listing={l}
              key={l.id.uuid}
              rootClassName={css.listingCard}
              rootImageClassName={css.listingImage}
              intl={intl}
              likedListingsIds={likedListingsIds}
              addUserFavorite={addUserFavorite}
              removeUserFavorite={removeUserFavorite}
              currentUserId={currentUserId}
            />
          );
        })}

        {props.children}
      </div>
      {paginationLinks}
    </div>
  );

  function renderUpsellCard() {
    return (
      <Stack
        alignItems="center"
        justifyContent="center"
        gap={1}
        bgcolor="#FAFAFA"
        border="1px solid #E9ECEF"
        borderRadius="12px"
        padding={2}
        height={264}
      >
        <Box component="img" src={ConfidenceIcon} />
        <Typography component="h3" fontSize={24} fontWeight={600} color="#07020D" align="center">
          {intl.formatMessage({ id: 'search_page.upsell_card.title' })}
        </Typography>
        <Typography variant="body1" color="#495057" align="center">
          {intl.formatMessage({ id: 'search_page.upsell_card.description' })}
        </Typography>
      </Stack>
    );
  }
}

SearchResultsPanel.defaultProps = {
  children: null,
  className: null,
  listings: [],
  pagination: null,
  rootClassName: null,
  search: null,
};

SearchResultsPanel.propTypes = {
  children: node,
  className: string,
  listings: array,
  pagination: propTypes.pagination,
  rootClassName: string,
  search: object,
};

export default SearchResultsPanel;
