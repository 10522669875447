import React from 'react';
import { Field } from 'react-final-form';
import classNames from 'classnames';
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import { ValidationError } from '../../../components';

import css from './MultiLevelSelect.module.css';

import ApproveIcon from '../../../assets/icons/interface/approve.png';

function MultiLevelSelectComponent(props) {
  const { rootClassName, meta, label, isMandatory, id, input, options, intl } = props;

  const params = useParams();
  const selectedType = params.type;
  const selectedCategory = params.category;
  const selectedSubCategory = params.subCategory;

  const handleSelection = (type, category, subCategory) => {
    input.onChange(`${type},${category},${subCategory}`);
  };

  const selectTypeIcon = type => {
    const selectedOption = options.find(option => option.key === selectedType);
    const isCategoryValid =
      selectedOption && selectedCategory
        ? selectedOption.options.find(category => category.key === selectedCategory) !== undefined
        : false;

    if (selectedType !== type) return false;
    if (selectedType === type && selectedCategory && isCategoryValid) return false;

    return true;
  };

  const selectCategoryIcon = category => {
    const selectedOption = options.find(option => option.key === selectedType);
    const selectedTypeCategory =
      selectedOption && selectedCategory ? selectedOption.options?.find(cat => cat.key === selectedCategory) : false;
    const isSubcategoryValid = selectedTypeCategory
      ? selectedTypeCategory.options?.find(subCategory => subCategory.key === selectedSubCategory) !== undefined
      : null;

    if (selectedTypeCategory === undefined) return false;
    if (selectedCategory !== category) return false;
    if (selectedCategory === category && selectedSubCategory && isSubcategoryValid) return false;

    return true;
  };

  const selectSubCategoryIcon = subCategory => {
    const selectedOption = options.find(option => option.key === selectedType);
    const selectedTypeCategory =
      selectedOption && selectedCategory ? selectedOption.options?.find(cat => cat.key === selectedCategory) : false;
    const selectedCategorySubCategory = selectedTypeCategory
      ? selectedTypeCategory.options?.find(subCat => subCat.key === selectedSubCategory)
      : null;

    if (selectedCategorySubCategory === undefined) return false;
    if (selectedSubCategory !== subCategory) return false;

    return true;
  };

  return (
    <div className={classNames(css.root, rootClassName)}>
      <div className={css.labelWrapper}>
        {label ? (
          isMandatory ? (
            <label className={css.label} htmlFor={id}>
              {label} <span className={css.mandatoryComponent}>*</span>
            </label>
          ) : (
            <label className={css.label} htmlFor={id}>
              {label}
            </label>
          )
        ) : null}
      </div>
      <div className={css.formRoot}>
        {options.map(option => (
          <div key={option.key} className={css.formTypeWrapper}>
            <div className={css.selectionWrapper} onClick={() => handleSelection(option.key, undefined, undefined)}>
              <span className={selectTypeIcon(option.key) ? css.selectedOptionLabel : css.optionLabel}>
                {intl.formatMessage({ id: `categories.${option.key}` })}
              </span>
              {selectTypeIcon(option.key) ? <img src={ApproveIcon} className={css.selectedIcon} /> : null}
            </div>
            {selectedType === option.key &&
              option?.options?.map(category => (
                <div className={css.formCategoryWrapper} key={category.key}>
                  <div
                    className={css.selectionWrapper}
                    onClick={() => handleSelection(option.key, category.key, undefined)}
                  >
                    <span className={selectCategoryIcon(category.key) ? css.selectedOptionLabel : css.optionLabel}>
                      {intl.formatMessage({ id: `categories.${category.key}` })}
                    </span>
                    {selectCategoryIcon(category.key) ? <img src={ApproveIcon} className={css.selectedIcon} /> : null}
                  </div>
                  {selectedCategory === category.key &&
                    category?.options?.map(subCategory => (
                      <div className={css.formSubCategoryWrapper} key={subCategory.key}>
                        <div
                          className={css.selectionWrapper}
                          onClick={() => handleSelection(option.key, category.key, subCategory.key)}
                        >
                          <span
                            className={
                              selectSubCategoryIcon(subCategory.key) ? css.selectedOptionLabel : css.optionLabel
                            }
                          >
                            {intl.formatMessage({ id: `sub_categories.${subCategory.key}` })}
                          </span>
                          {selectSubCategoryIcon(subCategory.key) ? (
                            <img src={ApproveIcon} className={css.selectedIcon} />
                          ) : null}
                        </div>
                      </div>
                    ))}
                </div>
              ))}
          </div>
        ))}
      </div>
      <ValidationError fieldMeta={meta} />
    </div>
  );
}

function MultiLevelSelect(props) {
  return <Field component={MultiLevelSelectComponent} {...props} />;
}

export default MultiLevelSelect;
