import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import IconPlus from '../IconPlus/IconPlus';

import css from '../RangeFilter/RangeFilterPlain.module.css';
import MultiLevelFilterForm from './MultiLevelFilterForm';

function MultiLevelFilterPlain(props) {
  const { id, intl, label, queryParams, initialValues, onSubmit, options, showAsPopup, liveEdit } = props;

  const [isOpen, setIsOpen] = useState(true);

  const formatQuery = values => {
    const [pub_listingType, pub_category, pub_subCategory] = values.SearchFiltersMobile.catalogtree?.split(',');

    return { pub_listingType, pub_category, pub_subCategory };
  };

  const handleSubmit = values => {
    onSubmit(formatQuery(values));
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const initialValuesArray = () => {
    const values = {};

    queryParams.forEach(param => {
      values[param] = initialValues[param];
    });

    return values;
  };

  return (
    <div className={css.root}>
      <div className={css.filterHeader}>
        <button type="button" className={css.labelButton} onClick={toggleOpen}>
          <span className={css.labelButtonContent}>
            <span className={css.labelWrapper}>
              <span className={css.label}>
                <label className={css.label}>{label}</label>
              </span>
            </span>
            <span className={css.openSign}>
              <IconPlus isOpen={isOpen} />
            </span>
          </span>
        </button>
      </div>
      <div className={css.formWrapper}>
        <MultiLevelFilterForm
          id={id}
          initialValues={initialValuesArray()}
          onSubmit={handleSubmit}
          options={options}
          showAsPopup={showAsPopup}
          liveEdit={liveEdit}
          isOpen={isOpen}
          label={label}
        />
      </div>
    </div>
  );
}

export default injectIntl(MultiLevelFilterPlain);
