import React, { useState } from 'react';
import { injectIntl } from 'react-intl';
import IconPlus from '../IconPlus/IconPlus';

import css from './RangeFilterPlain.module.css';
import RangeFilterForm from './RangeFilterForm';

function RangeFilterPlain(props) {
  const { id, min, max, intl, label, queryParam, initialValues, onSubmit, options, showAsPopup, liveEdit } = props;

  const [isOpen, setIsOpen] = useState(false);

  const parseRange = range => {
    const values = range ? range.toString().split(',') : [];

    const [minValue, maxValue] = values.length === 1 ? [values[0], values[0]] : values;

    return !!range && minValue != null && maxValue != null
      ? minValue >= maxValue
        ? { minValue, maxValue }
        : { minValue: Number(minValue), maxValue: Number(maxValue) - 1 }
      : {};
  };

  const formatQuery = range => {
    const { minValue, maxValue } = range || {};

    const value =
      minValue != null && maxValue != null
        ? minValue >= maxValue
          ? minValue
          : `${Number(minValue)},${Number(maxValue) + 1}`
        : null;

    return { [queryParam]: value };
  };

  const handleSubmit = values => {
    onSubmit(formatQuery(values));
  };

  const toggleOpen = () => {
    setIsOpen(!isOpen);
  };

  const initialRange = initialValues && initialValues[queryParam] ? parseRange(initialValues[queryParam]) : {};

  const { minValue, maxValue } = initialRange;

  const hasInitialValues = initialValues && minValue && maxValue;

  return (
    <div className={css.root}>
      <div className={css.filterHeader}>
        <button type="button" className={css.labelButton} onClick={toggleOpen}>
          <span className={css.labelButtonContent}>
            <span className={css.labelWrapper}>
              <span className={css.label}>
                <label className={css.label}>{label}</label>
              </span>
            </span>
            <span className={css.openSign}>
              <IconPlus isOpen={isOpen} />
            </span>
          </span>
        </button>
      </div>
      <div className={css.formWrapper}>
        <RangeFilterForm
          id={id}
          initialValues={hasInitialValues ? initialRange : { minValue: min, maxValue: max }}
          onSubmit={handleSubmit}
          // onClear={handleClear}
          // onCancel={handleCancel}
          intl={intl}
          min={min}
          max={max}
          isOpen={isOpen}
          label={label}
          queryParam={queryParam}
          options={options}
          showAsPopup={showAsPopup}
          liveEdit={liveEdit}
        />
      </div>
    </div>
  );
}

export default injectIntl(RangeFilterPlain);
