import React, { Component } from 'react';
import { bool, func, object, node, number, shape, string } from 'prop-types';
import classNames from 'classnames';
import { withRouter } from 'react-router-dom';

import routeConfiguration from '../../../routing/routeConfiguration';
import { injectIntl, intlShape } from '../../../util/reactIntl';
import { createResourceLocatorString } from '../../../util/routes';

import { ModalInMobile, Button } from '../../../components';

import css from './SearchFiltersMobile.module.css';

class SearchFiltersMobileComponent extends Component {
  constructor(props) {
    super(props);
    this.state = { initialQueryParams: null };

    this.cancelFilters = this.cancelFilters.bind(this);
    this.closeFilters = this.closeFilters.bind(this);
    this.resetAll = this.resetAll.bind(this);
  }

  // Close the filters by clicking cancel, revert to the initial params
  cancelFilters() {
    const { history, onCloseModal, initialQueryParams } = this.props;

    history.push(createResourceLocatorString('SearchPage', routeConfiguration(), {}, initialQueryParams));
    onCloseModal();
  }

  // Close the filter modal
  closeFilters() {
    this.props.onCloseModal();
  }

  // Reset all filter query parameters
  resetAll(e) {
    this.props.resetAll(e);

    // blur event target if event is passed
    if (e && e.currentTarget) {
      e.currentTarget.blur();
    }
  }

  render() {
    const {
      rootClassName,
      className,
      children,
      resultsCount,
      showAsModalMaxWidth,
      onManageDisableScrolling,
      intl,
      isFiltersOpenOnMobile,
      hideTopBar,
    } = this.props;

    const classes = classNames(rootClassName || css.root, className);

    const modalCloseButtonMessage = intl.formatMessage({ id: 'actions.cancel' });

    const showListingsLabel = intl.formatMessage({ id: 'search_page.mobile.show_listings' }, { count: resultsCount });

    return (
      <div className={classes}>
        <ModalInMobile
          id="SearchFiltersMobile.filters"
          isModalOpenOnMobile={isFiltersOpenOnMobile}
          onClose={this.cancelFilters}
          showAsModalMaxWidth={showAsModalMaxWidth}
          onManageDisableScrolling={onManageDisableScrolling}
          containerClassName={css.modalContainer}
          closeButtonMessage={modalCloseButtonMessage}
          modalTitle="Filters"
          hideTopBar={hideTopBar}
        >
          {isFiltersOpenOnMobile ? <div className={css.filtersWrapper}>{children}</div> : null}

          <div className={css.showListingsContainer}>
            <Button className={css.showListingsButton} onClick={this.closeFilters}>
              {showListingsLabel}
            </Button>
          </div>
        </ModalInMobile>
      </div>
    );
  }
}

SearchFiltersMobileComponent.defaultProps = {
  rootClassName: null,
  className: null,
  resultsCount: null,
  searchInProgress: false,
  selectedFiltersCount: 0,
};

SearchFiltersMobileComponent.propTypes = {
  rootClassName: string,
  className: string,
  urlQueryParams: object.isRequired,
  listingsAreLoaded: bool.isRequired,
  resultsCount: number,
  searchInProgress: bool,
  showAsModalMaxWidth: number.isRequired,
  onManageDisableScrolling: func.isRequired,
  onOpenModal: func.isRequired,
  onCloseModal: func.isRequired,
  resetAll: func.isRequired,
  selectedFiltersCount: number,

  // from injectIntl
  intl: intlShape.isRequired,

  // from withRouter
  history: shape({
    push: func.isRequired,
  }).isRequired,
};

const SearchFiltersMobile = injectIntl(withRouter(SearchFiltersMobileComponent));

export default SearchFiltersMobile;
