import classNames from 'classnames';
import React from 'react';
import { Form as FinalForm, FormSpy } from 'react-final-form';
import { injectIntl } from 'react-intl';

import css from './RangeFilterForm.module.css';
import { Form } from '../../../components';
import FieldDropdownSelect from '../../EditListingPage/EditListingWizard/FieldDropdownSelect';
import Spacer, { HORIZONTALL_AXIS } from '../../../components/Spacer/Spacer';

function RangeFilterFormComponent(props) {
  const { onChange, onSubmit, onCancel, onClear, queryParam, ...rest } = props;

  const handleSubmit = values => {
    const { minValue, maxValue, ...restValues } = values;
    return onSubmit({
      minValue: minValue === '' ? rest.min : minValue,
      maxValue: maxValue === '' ? rest.max : maxValue,
      ...restValues,
    });
  };

  const formCallbacks = { onSubmit: handleSubmit, onCancel, onClear };

  return (
    <FinalForm
      {...rest}
      {...formCallbacks}
      render={formRenderProps => {
        const {
          form,
          handleSubmit,
          id,
          onCancel,
          onClear,
          showAsPopup,
          isOpen,
          label,
          intl,
          options,
          liveEdit,
        } = formRenderProps;

        const clear = intl.formatMessage({ id: 'search_page.price_filter.clear' });
        const cancel = intl.formatMessage({ id: 'search_page.price_filter.cancel' });
        const submit = intl.formatMessage({ id: 'search_page.price_filter.submit' });

        const handleCancel = () => {
          form.reset();
          onCancel();
        };

        const handleChange = formState => {
          if (formState.dirty) {
            onSubmit(formState.values);
          }
        };

        const classes = classNames(css.root, {
          [css.isOpenAsPopup]: showAsPopup && isOpen,
          [css.plain]: !showAsPopup,
          [css.isOpen]: !showAsPopup && isOpen,
        });

        return (
          <Form className={classes} onSubmit={handleSubmit}>
            <div className={css.contentWrapper}>
              <div className={css.inputsWrapper}>
                <FieldDropdownSelect
                  rootClassName={css.dropdownRoot}
                  id={`${id}.minValue`}
                  name="minValue"
                  options={options}
                  intl={intl}
                  placeholder="from"
                />
                <Spacer axis={HORIZONTALL_AXIS} size={32} />
                <FieldDropdownSelect
                  rootClassName={css.dropdownRoot}
                  id={`${id}.maxValue`}
                  name="maxValue"
                  options={options}
                  intl={intl}
                  placeholder="to"
                />
              </div>

              {liveEdit ? (
                <FormSpy onChange={handleChange} subscription={{ values: true, dirty: true }} />
              ) : (
                <div className={css.buttonsWrapper}>
                  <button className={css.clearButton} type="button" onClick={onClear}>
                    {clear}
                  </button>
                  <button className={css.cancelButton} type="button" onClick={handleCancel}>
                    {cancel}
                  </button>
                  <button className={css.submitButton} type="submit">
                    {submit}
                  </button>
                </div>
              )}
            </div>
          </Form>
        );
      }}
    />
  );
}

const RangeFilterForm = injectIntl(RangeFilterFormComponent);

export default RangeFilterForm;
