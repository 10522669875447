import React from 'react';
import MultiLevelFilterPlain from './MultiLevelFilterPlain';

function MultiLevelFilter(props) {
  const { showAsPopup, queryParamNames, ...rest } = props;

  return showAsPopup ? (
    <MultiLevelFilterPlain showAsPopup={showAsPopup} queryParams={queryParamNames} {...rest} />
  ) : (
    <MultiLevelFilterPlain liveEdit showAsPopup={showAsPopup} queryParams={queryParamNames} {...rest} />
  );
}

MultiLevelFilter.defaultProps = {
  showAsPopup: false,
};

export default MultiLevelFilter;
